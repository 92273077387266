var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row",
      },
      [
        _c(
          "div",
          {
            staticClass: "col-3",
          },
          [
            _c(
              "div",
              {
                staticClass: "are-consultant-picture",
              },
              [
                _c("b-skeleton", {
                  attrs: {
                    type: "avatar",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-9",
          },
          [
            _c(
              "h2",
              {
                staticClass: "h2-consultant-name",
              },
              [
                _c("b-skeleton", {
                  attrs: {
                    width: "20%",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "row consultant-title",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-1",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "txt-consultant-title",
                      },
                      [_vm._v(_vm._s(_vm.$t("from")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-11",
                  },
                  [
                    _c("b-skeleton", {
                      attrs: {
                        width: "30%",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _c(
      "p",
      {
        staticClass: "txt-consultant-introduction",
      },
      [
        _c(
          "span",
          {
            staticClass: "txt-consultant-title",
          },
          [_vm._v(_vm._s(_vm.$t("introduction")))]
        ),
        _c("b-skeleton", {
          attrs: {
            width: "100%",
          },
        }),
      ],
      1
    ),
    _c(
      "p",
      {
        staticClass: "txt-consultant-suggestion",
      },
      [
        _c(
          "span",
          {
            staticClass: "txt-consultant-title",
          },
          [_vm._v(_vm._s(_vm.$t("suggestion")))]
        ),
        _c("b-skeleton", {
          attrs: {
            width: "100%",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "are-consultant-control",
      },
      [
        _c("b-skeleton", {
          attrs: {
            type: "button",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }